@import url('https://fonts.googleapis.com/css2?family=Old+Standard+TT&display=swap');

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

html {
	scroll-behavior: smooth;
}

.otp-container {
	padding: 0.5em 0;
	width: 100%;
	max-width: 300px;
	margin: 1em auto;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.otp-input {
	/*width: 15% !important;
	height: 45px !important;
	*/
	width: 30px !important;
	height: 30px !important;
	margin-right: 0px !important;
	font-size: 14px !important;
	text-align: center;
	color: #494949;
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
	border: 1px solid var(--main-color);
	border-radius: 12px;
	/* text-transform: uppercase;
	color: #494949;
	font-family: Helvetica Neue, Helvetica, Arial,	  sans-serif;
	border: 1px solid var(--main-color);
	border-radius: 12px;
	background: #fff; */
}

.otp-input:focus {
	appearance: none;
	outline: 0;
	box-shadow: 0 0 0 3px rgb(131 192 253 / 50%);
}

.MuiLinearProgress-root {
	height: 5px !important;
}

.MuiLinearProgress-indeterminate {
	background-color: #dfdb11 !important;
}

.MuiLinearProgress-bar1Indeterminate {
	background-color: rgb(26, 226, 76) !important;
}

.MuiLinearProgress-bar2Indeterminate {
	background-color: rgb(250, 77, 77) !important;
}

li span {
	margin-left: -4px;
}

.base-main-container {
	height: 100%;
	height: -moz-available;
	/* WebKit-based browsers will ignore this. */
	height: -webkit-fill-available;
	/* Mozilla-based browsers will ignore this. */
	height: stretch;
}

a {
	color: black;
}

a:link {
	color: black;
}