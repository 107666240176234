.container-500 {
  background-color: #007aff;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  line-height: 1.5;
  font-family: "Roboto", sans-serif;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow:inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.button-500 {
  font-weight: 500;
  color: #fff;
  font-size: 1.2em;
  text-decoration: none;
  border: 2px solid #efefef;
  padding: .5em;
  border-radius: 3px;
  margin: 1em;
  text-transform: uppercase;
  transition: all .3s linear;
  cursor: pointer;
  background-color: #393a3b;
}

.button-500:hover {
  cursor: pointer;
  background-color: #007aff;
  color: #fff;
}

.p-500 {
  font-size: 2em;
  text-align: center;
  font-weight: 400;
}

.h1--500 {
  text-align: center;
  font-size: 5em;
  margin: 0 !important;
  font-weight: bold;
  
}

.code-500 {
  display: block;
  background: none;
  white-space: pre;
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
  font-size: auto;
  max-width: 100%;
  /* min-width: 100px; */
  margin: 1em;
}