@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

:root {
  --main-color: #2365B8;
  --global-font-size: 80.5%;
  --global-font-family: 'Lato'
}


body {
  margin: 0;
  font-family: var(--global-font-family), -apple-system, BlinkMacSystemFont, 'Helvetica CE 55 Roman', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--global-font-size);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


* {
  font-family: var(--global-font-family), 'Lato', sans-serif !important;
}

/* Scrollbar Modifications */

::-webkit-scrollbar {
  position: absolute;
  width: 6px;
  height: 6px;
  margin: 0;
  cursor: pointer;
}

::-webkit-scrollbar-corner,
::-webkit-scrollbar-track {
  background-color: #eae7e7;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--button-color);
  background-clip: padding-box;
  border: 1px solid transparent;
  border-radius: 1em;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #949596;
}

::-webkit-scrollbar-thumb:active {
  background-color: #949596;
}

/* Scrollbar Modifications */

li {
  margin: 5px !important;
}

#feedbacklabelspan {
  position: relative;
  left: -6em;
}

.list-item-container-issuer {
  flex-direction: row !important;
}

.custom-fieldset {
  border: 1px solid var(--main-color);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom: 0 !important;
  margin: 0;
  padding-top: 0;
  margin-top: 1em;
}

fieldset>legend {
  font-size: 12px;
  font-weight: 400;
}

#feedbPopupSbmtBtn {

  display: -webkit-inline-box !important;
  display: -webkit-inline-flex !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
  -webkit-align-items: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  -webkit-justify-content: center !important;
  justify-content: center !important;
  position: relative !important;
  box-sizing: border-box !important;
  -webkit-tap-highlight-color: transparent !important;
  background-color: transparent !important;
  outline: 0 !important;
  border: 0 !important;
  margin: 0 !important;
  border-radius: 0 !important;
  padding: 0 !important;
  cursor: pointer !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  vertical-align: middle !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  -webkit-text-decoration: none !important;
  text-decoration: none !important;
  color: inherit !important;
  font-family: var(--global-font-family), "Lato", "Helvetica", "Arial", sans-serif !important;
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  line-height: 1.75 !important;
  letter-spacing: 0.02857em !important;
  min-width: 64px !important;
  padding: 6px 16px !important;
  border-radius: 4px !important;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  color: #fff !important;
  background-color: var(--button-color) !important;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
  border-radius: 100px !important;
  background: var(--button-color) !important;
  color: #ffffff !important;
  padding: 10px 48px !important;
  margin: 1em !important;
}

.zstitlemini {
  visibility: hidden;
  position: relative;
}

#advwebForm .zstitlemini {
  overflow: initial !important;
}

.zstitlemini:after {
  visibility: visible;
  position: absolute;
  top: 0;
  left: 0;
  content: "Facing some issue. Reach out to us";
  font-family: var(--global-font-family), 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off, 'calt' off;

  color: #222222;
}

#feedbacklabelspan {
  display: none !important;
}

.Mui-selected {
  background-color: var(--main-shade-color) !important;
}

/* Ripple effect */
.ripple {
  background-position: center;
  transition: background 0.8s;
}

.ripple:hover {
  background: var(--main-shade-color) radial-gradient(circle, transparent 1%, var(--main-shade-color) 1%) center/15000%;
}

.ripple:active {
  background-color: #fafafa;
  background-size: 100%;
  transition: background 0s;
}