#loader {
    border: 0px solid #f3f3f3;
    border-bottom: 2px solid #F5BE88;
    border-top: 2px solid #559B4D;
    /* border-left: 2px solid #559B4D; */
    border-radius: 51%;
    width: 150px;
    height: 150px;
    animation: spin 1s linear infinite;

}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading-img {
    width: 100px;
    height: 100px;
    /* clip-path: inset(29px 0px 0px); */
    border-radius: 85%;
    margin: 1em;
    position: absolute;

    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}